<template>
  <component
    v-bind="$attrs"
    :is="tag"
    :class="
      cn([
        'py-s flex h-full flex-col items-center justify-center',
        props.class,
        { 'p-l shadow-elevated rounded-sm bg-neutral-100': grayCard },
        classes?.root,
      ])
    "
  >
    <div v-if="withImg" :class="cn('nd__image', classes?.image)">
      <UiNuxtIcon name="cactus-no-data" filled />
    </div>

    <div :class="cn('nd__inner-wrapper', classes?.innerWrapper)">
      <span v-if="getTitle" v-html="getTitle" :class="cn('block text-lg', classes?.title)" />
      <span v-if="getText" v-html="getText" :class="cn('mt-xxxs block px-5', classes?.text)" />
    </div>

    <div v-if="hasDefaultSlot" :class="cn('nd__slot', classes?.slot)">
      <slot />
    </div>
  </component>
</template>

<script lang="ts" setup>
type UiNoDataClasses = {
  root: ClassValue
  image: ClassValue
  innerWrapper: ClassValue
  slot: ClassValue
  text: ClassValue
  title: ClassValue
}

const props = withDefaults(
  defineProps<{
    title?: string
    text?: string
    config?: { title?: string; text?: string }
    grayCard?: boolean
    withImg?: boolean
    tag?: string
    class?: ClassValue
    classes?: Partial<UiNoDataClasses>
  }>(),
  {
    withImg: true,
    tag: "div",
  }
)

const slots = useSlots()
const hasDefaultSlot = computed(() => !!slots.default?.())
const getTitle = computed(() => props.config?.title ?? props.title)
const getText = computed(() => props.config?.text ?? props.text)
</script>

<style lang="scss">
.nd {
  &__image {
    width: 100%;
    text-align: center;
    margin-block-end: theme.$spacing-xs;
    span {
      width: min(100%, 278px);
      height: fit-content;
      margin-inline: auto;

      svg {
        width: 100%;
        height: 100%;
        max-height: 135px;
      }
    }
    @include theme.media("<md") {
      margin-block-end: 1.875rem;
    }
  }
  &__inner-wrapper {
    text-align: center;
  }
  span {
    white-space: pre-line;
  }
  &__slot {
    margin-top: theme.$spacing-m;
  }
}
</style>
